// Home.js

import { useState } from 'react';
import { Amplify, API } from 'aws-amplify';




export default function Home() {


  return (
    <div>
    <h2>Home</h2>
    <p>This website has some tools to help you with Word games such as the NYT Spelling Bee and Crossword Games.</p>
    </div>
  );
}
