// CrossWord.js

import { useState } from 'react';
import { Amplify, API } from 'aws-amplify';

export default function CrossWord() {
  const oneIndexedRange = (length) => Array.from({length: length}, (v, i) => i+1);
  const emptyArrayOfSize = (length) => Array.from({length: length}, (v, i) => null);

  const [someText, setSomeText] = useState('Choose the length of the word you are looking for and the known letters, then press submit to search for words fitting this criteria.');
  const [words, setWords] = useState([]);
  const [wordLen, setWordLen] = useState(3);
  const [inputLetterIndexes, setInputLetterIndexes] = useState(oneIndexedRange(wordLen));
  const [inputLetters, setInputLetters] = useState(emptyArrayOfSize(wordLen));
  const [checked, setChecked] = useState([]);

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };



  // Return classes based on whether item is checked
  const isChecked = (item) => checked.includes(item) ? "checked-item" : "not-checked-item";

  const handleSubmit = (event) => {
    event.preventDefault();

    setSomeText('Loading words...');
    callApi();
  }

  const handleWordLenChange = (len) => {
    setWordLen(len);
//    const newInputLetters = emptyArrayOfSize(len);
//    for (let i = 0; i < len; i++) {
//      newInputLetters[i] = inputLetters[i]
//    }
//    setInputLetters(newInputLetters);
    setInputLetters(emptyArrayOfSize(len));
  }

   function getData() {
      const apiName = 'crossWordApi';
      const path = '/crossWord';

      const queryStringParameters = {
        'wordLen': wordLen
      }
      // add one indexed, letter query parameters
      for (let i = 0; i < wordLen; i++) {
        if (!!inputLetters[i]) {
          queryStringParameters[i+1] = inputLetters[i]
        }
      }
      const myInit = {
            headers: {}, // OPTIONAL
//            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            'queryStringParameters': queryStringParameters
       };


      return API.get(apiName, path, myInit);
   }


    async function callApi() {
        const response = await getData();
        setSomeText('Words:')
        setWords(response['words'])
    };

  return (
    <div>
    <h2>Crossword Helper</h2>
    <form onSubmit = {handleSubmit}>
      <div style={{ marginBottom: '10%'}}>
        <label>
          Word Length:
          <input
              type="number"
              name="wordLen"
              id="wordLen"
              defaultValue={3}
              min={3}
              max={31}
              style={{ margin: '.25%', maxWidth: "100px"}}
              onChange={(e) => handleWordLenChange(e.target.value)}
           />
        </label>
      </div>
      <div className="letter-container">
        {inputLetters.map((item, index) => (
          <div key={index}>
            <input
              type="text"
              maxLength="1"
              placeholder={index+1}
              style={{ margin: '.25%', maxWidth: "50px"}}
              onChange={(e) => inputLetters[index] = e.target.value}
            />
          </div>
        ))}
      </div>
      <input
        type="submit"
        style={{ margin: '.25%'}}
      />
    </form>
    <p>{someText}</p>
    <div className="list-container">
      {words.map((item, index) => (
         <div key={index}>
           <input value={item} type="checkbox" onChange={handleCheck} />
           <span className={isChecked(item)}>{item}</span>
         </div>
      ))}
    </div>
    </div>
  );
}
