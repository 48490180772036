// About.js

import { useState} from 'react';

export default function About() {


  return (
    <div>
    <h2>About</h2>
    <p>I created this website as a fun side project to improve my web dev and programming skills.  I hope you find it helpful!!</p>
    </div>
  );
}