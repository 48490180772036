// Contact.js

import { useState} from 'react';

export default function Contact() {
  const [someText, setSomeText] = useState('judecapachietti@gmail.com');


  return (
    <div>
    <h2>Contact</h2>
    <p>For any inquires reach out to: {someText}</p>
    </div>
  );
}