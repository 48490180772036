// SpellingBee.js

import { useState } from 'react';
import { Amplify, API } from 'aws-amplify';

export default function SpellingBee() {
  const [someText, setSomeText] = useState('Enter \'All Letters\' (with no commas or spaces between) and \'Center Letter\', then press \'Submit\' to see Spelling Bee Word List.');
  const [words, setWords] = useState([]);
  const [allLetters, setAllLetters] = useState('');
  const [centerLetter, setCenterLetter] = useState('');
  const [checked, setChecked] = useState([]);
  const [useAltWordList, setUseAltWordList] = useState(false);

  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const flipUseAltWordList = () => setUseAltWordList(!useAltWordList);

  // Return classes based on whether item is checked
  const isChecked = (item) => checked.includes(item) ? "checked-item" : "not-checked-item";

  const handleSubmit = (event) => {
    event.preventDefault();

    if ( ![6,7].includes(allLetters.length) || !/^[a-zA-Z]+$/.test(allLetters)) {
        setSomeText('Please ensure "All Letters" input has 6 or 7 letters (either including or excluding center letter is fine).')
        return
    }
    if (centerLetter.length !== 1 ||  !/^[a-zA-Z]+$/.test(centerLetter)){
        setSomeText('Please ensure "Center Letter" input has exactly 1 letter.')
        return
    }
    setSomeText('Loading words...')
    callApi();
  }

   function getData() {
      const apiName = 'spellingBeeApi';
      const path = '/spellingBee';
      const myInit = {
            headers: {}, // OPTIONAL
//            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {
            'allLetters': allLetters,
            'centerLetter': centerLetter,
            'useAltWordList': useAltWordList
            }
       };

      return API.get(apiName, path, myInit);
   }


    async function callApi() {
        const response = await getData();
        setSomeText('Words:')
        setWords(response['words'])
    };

//    callApi();
  return (
    <div>
    <h2>Spelling Bee Helper</h2>
    <form onSubmit = {handleSubmit}>
    <label>
      All Letters:
    </label>
    <br></br>
    <input
        type="text"
        name="allLetters"
        minLength={6}
        maxLength={7}
        value={allLetters}
        style={{ margin: '1%'}}
        onChange={(e) => setAllLetters(e.target.value)}
    />
      <br></br>
      <label>
        Center Letter:
      </label>
      <br></br>
      <input
          type="text"
          name="centerLetter"
          minLength={1}
          maxLength={1}
          value={centerLetter}
          style={{ margin: '1%'}}
          onChange={(e) => setCenterLetter(e.target.value)}
      />
      <br></br>
      <label>
        Use longer word list:
        <input
            type="checkbox"
            onChange={flipUseAltWordList}
            style={{ margin: '1%'}}
        />
      </label>
      <br></br>
      <input
        type="submit"
        style={{ margin: '1%'}}
      />
    </form>
    <p>{someText}</p>
    <div className="list-container">
      {words.map((item, index) => (
         <div key={index}>
           <input value={item} type="checkbox" onChange={handleCheck} />
           <span className={isChecked(item)}>{item}</span>
         </div>
      ))}
    </div>
    </div>
  );
}
