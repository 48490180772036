import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// import roots/components
import Root from "./routes/Root";
import Contact from "./routes/Contact";
import About from "./routes/About";
import Home from "./routes/Home";
import CrossWord from "./routes/CrossWord";
import SpellingBee from "./routes/SpellingBee";
import ErrorPage from "./error-page";

// set up router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// import/configure amplify stuff.
// Tutorial link: https://docs.amplify.aws/start/getting-started/setup/q/integration/react/#create-a-new-react-app
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

// router logic adapted from this tutorial: https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
 {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
     {
      // load <Home /> in as default Outlet
        index: true,
        element: <Home />,
      },
      {
      // also render <Home /> component with /Home
        path: "Home",
        element: <Home />,
      },
      {
        path: "CrossWord",
        element: <CrossWord />,
      },
      {
        path: "SpellingBee",
        element: <SpellingBee />,
      },
      {
        path: "Contact",
        element: <Contact />,
      },
      {
        path: "About",
        element: <About />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
