import { Outlet, Link } from "react-router-dom";
import { useState } from 'react';
import { ConsoleLogger } from "@aws-amplify/core";

export default function Root() {
  const sideBarOpenWidth = '15rem'
  const [sideBarVisiblility, setSideBarVisiblility] = useState('hidden');
  const [sideBarWidth, setSideBarWidth] = useState('0');

  function handleClick() {
    if (sideBarVisiblility === 'visible') {
      setSideBarVisiblility('hidden')
      setSideBarWidth('0')
    }
    if (sideBarVisiblility === 'hidden') {
      setSideBarVisiblility('visible')
      setSideBarWidth(sideBarOpenWidth)
    }
  }
  return (
    <>
      <div style={{width: '100%', height: '10%', position: 'fixed', backgroundColor: '#1b0e33', zIndex: '3000'}}>
        {/* <img src="/word_help.png" style={{ height: '100%'}}></img> */}
        <div id="icon-container" style={{ 
          // backgroundColor: 'black', 
          height: '10%', 
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          width: '100px',
          }}>
          <h4 style={{
            // backgroundColor: 'white',
            fontFamily: 'American Typewriter, serif', 
            paddingLeft: '10px', 
            margin: '0',
            color: 'white',
            }}>
            Word Helper
          </h4>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', maxWidth: '60%', height: '90%', position: 'fixed', top: '10%', zIndex: '3000'}}>
        <div style={{backgroundColor: '#404040'}}>
          <button style={{backgroundColor: '#666666', color: 'black', border: '1.5px solid black', boxShadow: 'none', borderRadius: '0px'}} onClick={() => handleClick()}>=</button>
        </div>
        <div id="sidebar" style={{visibility: `${sideBarVisiblility}`, width: `${sideBarWidth}`, maxWidth: '100%'}}>
          <nav style={{backgroundColor: '#969696'}}>
            <ul>
              <li>
                <Link to={`Home`}>Home</Link>
              </li>
              <li>
                <Link to={`SpellingBee`} >Spelling Bee Helper</Link>
              </li>
              <li>
                <Link to={`CrossWord`}>Crossword Helper</Link>
              </li>
              <li>
                <Link to={`About`}>About</Link>
              </li>
              <li>
                <Link to={`Contact`}>Contact</Link>
              </li>
            </ul>
          </nav>
          {/* <h1>Word Helper</h1> */}
        </div>
      </div>
      <div style={{display: 'inline-block', width: '100%'}}>
        <div style={{height: '10%', width: '100%', zIndex: '1000'}}></div>
        <div id="detail" style={{zIndex: '1000'}}>
          <Outlet />
        </div>
      </div>
      
    </>
  );
}